import { datadogRum } from '@datadog/browser-rum';

export const logError = (error: any, args: Record<string, any> = {}) => {
  const logObj: Record<string, any> = { ...args };

  logObj.response = error?.response?.data;
  logObj.status = error?.status;
  logObj.responseHeaders = error?.response?.headers;
  logObj.requestHeaders = error?.request?.headers;

  datadogRum.addError(error, logObj);
};
