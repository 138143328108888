import { useQuery } from '@tanstack/react-query';

import { QueryKey } from 'src/constants/queryKeys';
import { formatDate } from 'src/types/utils';
import type { Pagination } from 'src/utils/SmartHireClient';
import { logError } from 'src/utils/errorHandler';

import { useSmartHireClient } from '../useSmartHireClient/useSmartHireClient';

interface UseGetJobsProps {
  pageNumber?: number;
  pageSize?: number;
}

export interface UseGetJobItem {
  id: number;
  roleId: number;
  adPostedAt: string;
  adExpiredAt: string;
  title: string;
  totalContactedCandidates: number;
  totalShortlistedCandidates: number;
  totalReviewedCandidates: number;
  totalPendingCandidates: number;
  totalSentToHirerCandidates: number;
  daysLeftToExpiry: number;
  expired: boolean;
  disabled: boolean;
}

interface UseGetJobsResponse {
  jobs: UseGetJobItem[];
  pagination?: Pagination;
}

interface GetJobsResponse {
  id: number;
  roleId: number | null;
  title: string;
  totalContactedCandidates: number;
  totalShortlistedCandidates: number;
  totalReviewedCandidates: number;
  totalPendingCandidates: number;
  totalSentToHirerCandidates: number;
  daysLeftToExpiry: number;
  expired: boolean;
  disabled: boolean;
  jobAds: Array<{
    adPostedAt: string;
    adExpiredAt: string;
  }>;
}

export const useGetJobs = ({
  pageNumber = 1,
  pageSize = 20,
}: UseGetJobsProps) => {
  const { smartHireClient } = useSmartHireClient();

  const fetchJobs = async (): Promise<UseGetJobsResponse> => {
    try {
      const { data, meta } = await smartHireClient.send<GetJobsResponse[]>({
        url: `/hirers/jobs`,
        params: {
          pageNumber,
          pageSize,
        },
      });

      if (data && Array.isArray(data)) {
        const jobs = data.map((job) => ({
          id: job.id,
          roleId: job.roleId ?? 0,
          adPostedAt:
            formatDate(job.jobAds[0]?.adPostedAt) || 'No Ad Posted Date',
          adExpiredAt: job.jobAds[0]?.adExpiredAt,
          title: job.title,
          totalContactedCandidates: job.totalContactedCandidates,
          totalShortlistedCandidates: job.totalShortlistedCandidates,
          totalReviewedCandidates: job.totalReviewedCandidates,
          totalPendingCandidates: job.totalPendingCandidates,
          totalSentToHirerCandidates: job.totalSentToHirerCandidates,
          daysLeftToExpiry: job.daysLeftToExpiry,
          expired: job.expired,
          disabled: job.disabled,
        }));

        return { jobs, pagination: meta?.pagination };
      }
      throw new Error('Invalid response structure: Expected jobs array');
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  return useQuery<UseGetJobsResponse, Error>({
    queryKey: [QueryKey.JOBS, pageNumber, pageSize],
    queryFn: fetchJobs,
  });
};
