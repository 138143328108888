export enum QueryKey {
  COMPANY = 'COMPANY',
  HIRERS = 'HIRERS',
  CURRENT_HIRER = 'CURRENT_HIRER',
  JOBS = 'JOBS',
  JOB = 'JOB',
  MATCH_ID = 'MATCH_ID',
  MATCHES = 'MATCHES',
  CANDIDATE_ID = 'CANDIDATE_ID',
  NOTES = 'NOTES',
  STATUS = 'STATUS',
  SMART_HIRE_CANDIDATE = 'SMART_HIRE_CANDIDATE',
  MATCH_REQUIREMENTS = 'MATCH_REQUIREMENTS',
  MATCH_CONTACT_DETAILS = 'MATCH_CONTACT_DETAILS',
}
