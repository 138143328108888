import type { UseQueryResult } from '@tanstack/react-query';
import { createContext } from 'react';

import { useGetCurrentHirer } from 'src/hooks/useGetCurrentHirer/useGetCurrentHirer';
import type { Hirer } from 'src/types/types';

export type User = Hirer & {
  companyId: string;
  company: {
    seekCompanyId: number;
  };
};

export const UserContext = createContext<UseQueryResult<User, Error>>(
  {} as any,
);

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, error, isFetching } = useGetCurrentHirer();

  return (
    <UserContext.Provider
      value={{ data, error, isFetching } as UseQueryResult<User, Error>}
    >
      {children}
    </UserContext.Provider>
  );
};
